import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {notification, Spin} from 'antd';

import OutletListDrawer from 'components/OutletListDrawer';
import RejectApprovalOverlay from 'components/RejectApprovalOverlay';

import PromoForm from '../CreatePromoRequest/ExternalPromoForm/PromoForm';
import InternalPromoForm from '../CreatePromoRequest/InternalPromoForm';
import PromoDetailHeader from './PromoDetailHeader';
import PromoReviewModal from './PromoReviewModal';

import isEmpty from 'lodash/isEmpty';

import {approvePromoRequest, getPromoDetailReview, getPromoRequest} from 'utils/request/promo';
import {handleErrorFetch} from 'utils/utils';
import {PROMO_CATEGORY, REFETCH_PROMO_REQUEST} from 'utils/constants';

import localization from 'localization';
import broadcastChannel from 'utils/broadcastChannel';
const locale = localization.Promo.Review;

const PromoDetail = ({promoCategory}) => {
	const navigate = useNavigate();
	const {promoId} = useParams();

	const [promoData, setPromoData] = useState({});
	const [loadingData, setLoadingData] = useState(loadingData);

	const [promoType, setPromoType] = useState(null);
	const [providedLocation, setProvidedLocation] = useState([]);

	const [outletDrawerActive, setOutletDrawerActive] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);

	const [promoReviewModalActive, setPromoReviewModalActive] = useState(false);
	const [promoReviewData, setPromoReviewData] = useState({});

	const [showRejectModal, setShowRejectModal] = useState(false);

	const [loadingApprove, setLoadingApprove] = useState(false);

	const viewOutletList = () => {
		setOutletDrawerActive(true);
	};

	const previewPromo = async () => {
		// If promo data is empty, fetch else (data exist) show modal
		if (isEmpty(promoReviewData)) {
			try {
				setLoadingPreview(true);
				const response = await getPromoDetailReview(promoData.id);
				if (response.success && response?.data?.details?.length) {
					setPromoReviewData(response.data);
					setPromoReviewModalActive(true);
				} else {
					throw {};
				}
			} catch (error) {
				handleErrorFetch(error);
			} finally {
				setLoadingPreview(false);
			}
		} else {
			setPromoReviewModalActive(true);
		}
	};

	const handleApprove = async () => {
		try {
			setLoadingApprove(true);
			const response = await approvePromoRequest(promoData.id, promoCategory);
			if (response.success) {
				broadcastChannel.postMessage({action: REFETCH_PROMO_REQUEST, timestamp: Date.now(), promoCategory});
				notification.open({
					message: locale.Notification.Approve.Success.message,
					description: locale.Notification.Approve.Success.description.replace('{{id}}', promoData.id),
					type: 'success',
				});
				if (promoCategory === PROMO_CATEGORY.EXTERNAL) navigate('/promo/promo-request/price-cut');
				else navigate('/promo/promo-request/free-item');
			} else throw {};
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoadingApprove(false);
		}
	};

	const groupLocation = merchants => {
		if (merchants?.length) {
			const uniqueLocations = Array.from(new Set(merchants.map(item => item.Location.id)))
				.map(locationId => merchants.find(item => item.Location.id === locationId).Location);

			setProvidedLocation(uniqueLocations);
		}
	};

	const getPromoData = async () => {
		try {
			setLoadingData(true);
			const response = await getPromoRequest({id: promoId}, promoCategory);
			if (response?.success && response?.data?.rows?.length) {
				const dataEntry = response.data.rows[0];
				setPromoData(dataEntry);
				groupLocation(dataEntry?.Outlets);
				setPromoType(dataEntry?.templateType);
			} else {
				throw {};
			}
		} catch (error) {
			handleErrorFetch(error);
			navigate(`/promo/promo-request/${promoCategory}`, {replace: true});
		} finally {
			setLoadingData(false);
		}
	};

	useEffect(() => {
		getPromoData();
	}, []);

	return (
		<div className="bg-white h-full overflow-auto">
			{
				loadingData
					? (
						<Spin
							className='w-full mt-20'
							size='large'
						/>
					)
					: (
						<>
							<RejectApprovalOverlay
								visible={showRejectModal}
								approvalId={promoData?.id}
								promoCategory={promoCategory}
								refetchCards={() => {
									if (promoCategory === PROMO_CATEGORY.INTERNAL) navigate('/promo/promo-request/free-item');
									else navigate('/promo/promo-request/price-cut');
								}}
								requestType='promo'
								close={() => {
									setShowRejectModal(false);
								}}
							/>
							<OutletListDrawer
								visible={outletDrawerActive}
								outletList={promoData?.Outlets}
								onClose={() =>setOutletDrawerActive(false)}
							/>
							<PromoReviewModal
								visible={promoReviewModalActive}
								promoReviewData={promoReviewData}
								onClose={() =>setPromoReviewModalActive(false)}
								menuTemplateLabel={promoData?.MenuTemplate?.label}
								providedLocation={providedLocation}
							/>
							<PromoDetailHeader
								promoCategory={promoCategory}
								promoLabel={promoData?.label}
								outletCount={promoData?.Outlets?.length}
								viewOutletList={viewOutletList}
								previewPromo={previewPromo}
								loadingPreview={loadingPreview}
							/>
							<div className='px-6 pb-20'>
								{
									promoCategory === PROMO_CATEGORY.INTERNAL
										? (
											<InternalPromoForm
												promoData={promoData}
												showRejectModal={() => setShowRejectModal(true)}
												handleApprove={handleApprove}
												loadingApprove={loadingApprove}
											/>
										)
										: (
											<PromoForm
												promoData={promoData}
												promoType={promoType}
												showRejectModal={() => setShowRejectModal(true)}
												handleApprove={handleApprove}
												loadingApprove={loadingApprove}
											/>
										)
								}
							</div>
						</>
					)
			}
		</div>
	);
};

PromoDetail.defaultProps = {
	promoCategory: {},
};

PromoDetail.propTypes = {
	promoCategory: PropTypes.object,
};

export default PromoDetail;
