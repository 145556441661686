import React, {useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Alert, Button, Card, InputNumber, Radio, Typography} from 'antd';
import {CheckCircleFilled, ExclamationCircleFilled, FileDoneOutlined} from '@ant-design/icons';

import ItemWrapper from '../ItemWrapper';

import {INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE} from 'utils/constants';
import {moneyFormat, numberFormat} from 'utils/utils';

import localization from 'localization';
import {useEffect} from 'react';
const locale = localization.Promo.CreateNewPromo.Internal.RewardSetting;

const rewardTypeConfig = {
	NORMAL: {
		label: locale.rewardTypeNormal,
	},
	MULTIPLIER: {
		label: locale.rewardTypeMultiplier,
	},
};

const REWARD_TYPE_INITIAL_VALUE = {
	type: null,
	value: null,
};

const RewardSetting = ({openMenuPicker, rules, setFormFilled, freeItemIsMapped, handleMapMenu, promoData}) => {
	// State Section

	const [searchParams] = useSearchParams();
	const viewOnly = JSON.parse(searchParams?.get?.('viewOnly') || null);

	const [rewards, setRewards] = useState({
		menuId: null,
		menuLabel: null,
		quantity: null,
	});

	const [rewardType, setRewardType] = useState(REWARD_TYPE_INITIAL_VALUE);

	// Fn Section
	const handleOpenMenuPicker = () => {
		openMenuPicker({
			type: INTERNAL_PROMO_MENU_PICKER_MODAL_TYPE.FREE_ITEM,
			actionFn: record => setRewards(state => ({
				...state,
				menuId: record.id,
				menuLabel: record.menuLabel,
			})),
		});
	};

	// Lifecycle Section
	useEffect(() => {
		if (rules?.aov?.minAmount === null) {
			setRewardType(REWARD_TYPE_INITIAL_VALUE);
		}
	}, [rules]);

	useEffect(() => {
		let rewardSettingFilled = true;

		if (!rewards?.menuId || !rewards?.menuLabel || !rewards?.quantity) rewardSettingFilled = false;
		// if (rules?.aov?.minAmount !== null && !rewardType?.value) rewardSettingFilled = false;
		if (!rewardType.type || !rewardType.value) rewardSettingFilled = false;
		const payloadTemp = {
			rewards,
			rewardCount: rewardType.value,
		};
		// const payloadTemp = rewardSettingFilled
		// 	? {
		// 		rewards,
		// 		rewardCount: rewardType.value,
		// 	} : {};
		setFormFilled(state => ({
			...state,
			rewardSetting: rewardSettingFilled,
			rewardSettingPayload: payloadTemp,
		}));
	}, [rewards, rewardType, rules]);

	useEffect(() => {
		if (!viewOnly) return;
		const promoDetailsEntry = promoData?.details;
		setRewards(promoDetailsEntry?.rewards);
		setRewardType({
			type: promoDetailsEntry?.rewardCount === 1 ? rewardTypeConfig.NORMAL.label : rewardTypeConfig.MULTIPLIER.label,
			value: promoDetailsEntry?.rewardCount,
		});
	}, [viewOnly]);
	// Render
	return (
		<Card title={locale.title}>
			<div className='flex flex-col gap-6'>
				{
					freeItemIsMapped === false && (
						<Alert
							message={locale.freeMenuIsUnmap}
							type="error"
							icon={<ExclamationCircleFilled />}
							showIcon />
					)
				}
				{/* Menu Reward Section */}
				<ItemWrapper label={locale.freeMenuLabel}>
					{
						rewards?.menuId
							? (
								<div>
									<div className='flex gap-2 items-center'>
										<FileDoneOutlined className='font-medium' />
										<div className='flex items-center font-medium gap-1'>
											<Typography.Text>{rewards?.menuLabel}</Typography.Text>
											<Typography.Text type='secondary'>[{rewards?.menuId}]</Typography.Text>
										</div>
										{
											freeItemIsMapped === false && <ExclamationCircleFilled className='text-antd-red-5' />
										}
										{
											freeItemIsMapped === true && <CheckCircleFilled className='text-antd-green-6' />
										}
									</div>
									{
										!viewOnly && (
											<div className='flex gap-4 max-w-max pt-2'>
												<div
													onClick={handleOpenMenuPicker}
													className='text-antd-blue-6 cursor-pointer'
												>
													{locale.changeFreeMenu}
												</div>
												{
													freeItemIsMapped === false && (
														<div
															onClick={handleMapMenu}
															className='text-antd-blue-6 cursor-pointer'
														>
															{locale.mapMenu}
														</div>
													)
												}
											</div>
										)
									}
								</div>
							)
							: (
								<Button
									onClick={handleOpenMenuPicker}
									type='primary'>{locale.freeMenuBtn}
								</Button>
							)
					}
				</ItemWrapper>
				{/* End of Menu Reward Section */}

				{/* Quantity Section */}
				<ItemWrapper label={locale.quantityLabel}>
					<InputNumber
						disabled={viewOnly}
						parser={value => value.replace(/[,.]/g, '')}
						formatter={value => {
							if (value) {
								return numberFormat(value);
							}
							return value;
						}}
						controls={false}
						onChange={e => setRewards(state => ({
							...state,
							quantity: e,
						}))}
						value={rewards?.quantity}
						placeholder={locale.quantityPlaceholder}
						style={{width: 400}}
					/>
				</ItemWrapper>
				{/* End of Quantity Section */}

				{/* Reward Type Section */}
				<ItemWrapper label={locale.rewardTypeLabel}>
					<div className='flex flex-col gap-2'>
						<Radio.Group
							disabled={viewOnly}
							value={rewardType?.type}
							onChange={e => {
								setRewardType({
									type: e.target.value,
									value: e.target.value === rewardTypeConfig.NORMAL.label ? 1 : null,
								});
							}
							}
						>
							{Object.values(rewardTypeConfig).map(rewardType => (
								<Radio
									disabled={rewardType.label === rewardTypeConfig.MULTIPLIER.label && rules?.aov?.minAmount === null || viewOnly}
									key={rewardType.label}
									value={rewardType.label}>{rewardType.label}</Radio>
							))}
						</Radio.Group>
						{
							rewardType?.type
								? (
									<div className='flex flex-col'>
										<InputNumber
											parser={value => value.replace(/[,.]/g, '')}
											formatter={value => {
												if (value) {
													return numberFormat(value);
												}
												return value;
											}}
											controls={false}
											onChange={e => setRewardType(state => ({
												...state,
												value: e,
											}))}
											value={rewardType.value}
											disabled={rewardType.type === rewardTypeConfig.NORMAL.label || viewOnly}
											style={{width: 400}}
											addonBefore={locale.multiplierInputAddOn}
											placeholder={locale.multiplierInputPlaceholder}
										/>
										{rewardType?.value
											? (
												<Typography.Text type='secondary'>
													{
														locale.multiplierInputAlert.replace(
															'{{calculation}}',
															moneyFormat(
																{value: rewardType.value * rules?.aov?.minAmount},
															),
														)
													}
												</Typography.Text>
											)
											: null}
									</div>
								)
								: null
						}
					</div>
				</ItemWrapper>
				{/* End of Reward Type Section */}

			</div>
		</Card>
	);
};

RewardSetting.defaultProps = {
	openMenuPicker: () => null,
	rules: {},
	setFormFilled: () => null,
	freeItemIsMapped: null,
	handleMapMenu: () => null,
	promoData: {},
};

RewardSetting.propTypes = {
	openMenuPicker: PropTypes.func,
	rules: PropTypes.object,
	setFormFilled: PropTypes.func,
	freeItemIsMapped: PropTypes.bool,
	handleMapMenu: PropTypes.func,
	promoData: PropTypes.object,
};

export default RewardSetting;