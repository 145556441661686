import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import ProtectedComponent from 'components/ProtectedComponent';

import {Modal, Button, Steps, Typography} from 'antd';
import {CloseOutlined, FileSearchOutlined, FolderOpenOutlined} from '@ant-design/icons';
const {Text} = Typography;

import {addTagToString, dateFormat} from 'utils/utils';
import {PROMO_TYPE} from 'utils/constants';

import isEmpty from 'lodash/isEmpty';
import Moment from 'react-moment';
import moment from 'moment';

const {confirm} = Modal;

import GRABSVG from 'assets/images/grab.svg';
import GOJEKSVG from 'assets/images/gojek.svg';
import SHOPEESVG from 'assets/images/shopee.svg';
import FRESTOSVG from 'assets/images/fresto-black-logo.svg';
const platformImageSource = [
	{
		label: 'Grab',
		image: GRABSVG,
	},
	{
		label: 'Gojek',
		image: GOJEKSVG,
	},
	{
		label: 'Shopee',
		image: SHOPEESVG,
	},
];

import localization from 'localization';
const locale = localization.Global.RequestCard;

const RequestCard = ({
	cancelAction, reviewAction, cardData, localeConfig, extra, isInternal,
}) => {
	// const [isEditor, setIsEditor] = useState(false);
	const [cardAction, setCardAction] = useState({});
	const [platformImg, setPlatformImg] = useState(null);

	const decideCardColor = () => {
		const startTime = moment();
		const endTime = moment(cardData.actionTime);
		const duration = moment.duration(endTime.diff(startTime));

		const daysDiff = duration.asDays();

		if (cardData.status === 'pending') {
			if (daysDiff > 1) return 'bg-antd-blue-6';
			else if (daysDiff >= 0) return 'bg-antd-warning-6';
			else return 'bg-antd-red-5';
		} else {
			if (cardData.status === 'rejected') return 'bg-antd-red-5';
			else return 'bg-antd-green-6';
		}
	};

	const getPromoTypeLabel = promoType => {
		switch (promoType) {
		case PROMO_TYPE.SINGLE:
			return locale.Body.singlePromoLabel;
		case PROMO_TYPE.MULTIPLE:
			return locale.Body.multiplePromoLabel;
		case PROMO_TYPE.FLASH_SALE:
			return locale.Body.flashPromoLabel;
		default:
			return null;
		}
	};

	useEffect(() => {
		if (isEmpty(cardData)) return;
		if (isInternal || cardData?.type === 'internal') setPlatformImg(FRESTOSVG);
		else {
			platformImageSource.forEach(platform => {
				if (platform.label === cardData.Platform?.label) setPlatformImg(platform.image);
			});
		}

		if (cardData?.status !== 'pending') {
			const isApproved = cardData?.approvedBy;
			const payload = {
				type: isApproved ? 'approved' : 'rejected',
				timestamp: isApproved ? cardData?.approvedAt : cardData?.rejectedAt,
				username: isApproved ? cardData?.approver?.username : cardData?.rejector?.username,
			};
			setCardAction(payload);
		}
	}, [cardData]);

	return (
		<div className='rounded-md overflow-hidden shadow-lg'>

			{/* Header Section */}
			<div className={`${decideCardColor()} flex px-8 py-3 text-white font-medium justify-center`}>
				{
					isEmpty(cardAction)
						? (
							<div className='flex gap-1 items-center justify-center'>
								<div>{locale.Header.overdue} </div>
								<Moment
									fromNow>{cardData.actionTime}</Moment>
							</div>
						)
						: (
							<Text
								className='text-white'
								ellipsis={{
									tooltip: locale.Header.processed
										.replace('{{action}}', cardAction.type === 'approved' ? locale.Header.approved : locale.Header.rejected)
										.replace('{{name}}', cardAction.username)
										.replace('{{time}}', dateFormat(cardAction.timestamp)),
								}}
						  >
								{
									locale.Header.processed
										.replace('{{action}}', cardAction.type === 'approved' ? locale.Header.approved : locale.Header.rejected)
										.replace('{{name}}', cardAction.username)
										.replace('{{time}}', dateFormat(cardAction.timestamp))
								}
							</Text>
						)
				}
			</div>
			{/* End of Header Section */}

			{/* Body Section */}
			<div className='pt-6 px-8 pb-8 border rounded-b-md h-full'>

				<div className='flex gap-2 items-center justify-between'>
					<img
						className='h-5'
						src={platformImg}
					/>
					<Text>{
						addTagToString(
							locale.Body.idLabel
								.replace('{{type}}', localeConfig.idType),
							'{{value}}',
							<Text copyable>{cardData.id}</Text>)
					}</Text>
				</div>
				<div className='pt-3'>
					<div className='text-overlay-40'>{locale.Body.requestedBy.replace('{{requestedBy}}', cardData?.requester?.username)}</div>
					<div className='flex gap-1 items-center'>
						<Text
							className='text-xl font-medium'
							ellipsis={{tooltip: cardData?.label}}
						>{cardData?.label}</Text>
						{
							cardData?.menuTemplateId ? <Text type='secondary'>{`[${cardData?.menuTemplateId}]`}</Text> : null
						}
					</div>
					{
						Number.isInteger(cardData.taxAddedPercentage)
							?
							(
								<div className='text-antd-green-6'>
									{
										cardData.taxAddedPercentage === 0
											? locale.Body.taxExactPrice
											: locale.Body.taxSubtractFromTemplate
									}
								</div>
							)
							: null
					}
					{
						cardData?.useExistingStatus !== undefined
							? (
								<div className='text-antd-green-6'>
									{
										cardData?.useExistingStatus === true
											? locale.Body.useExistingStatus
											: locale.Body.turnOnAll
									}
								</div>

							)
							: null
					}
					{
						cardData?.templateType
							? (
								<div className='text-overlay-40'>
									{locale.Body.promoTypeInfo.replace('{{promoType}}', getPromoTypeLabel(cardData?.templateType))}
								</div>
							)
							: null
					}
				</div>

				<div className='pt-6'>
					<Steps
						direction="vertical"
						current={1}>
						<Steps.Step
							title={locale.Body.requestedAt}
							description={dateFormat(cardData.requestedAt)} />
						<Steps.Step
							title={locale.Body.actionAt.replace('{{action}}', localeConfig.actionType)}
							description={dateFormat(cardData.actionTime)} />
					</Steps>
				</div>

				{/* Action Button */}
				<div className='flex flex-col lg:flex-row flex-wrap gap-2 pt-6 justify-end'>
					{
						cardData?.status === 'pending'
							? (
								<ProtectedComponent when={u => u.username === cardData?.requester.username}>
									<Button
										icon={<CloseOutlined />}
										onClick={() => {
											confirm({
												title: locale.Modal.CancelConfirmation.title,
												content: locale.Modal.CancelConfirmation.content,
												okText: locale.Modal.CancelConfirmation.confirm,
												cancelText: locale.Modal.CancelConfirmation.close,
												onOk: () => cancelAction(),
												maskClosable: true,
												centered: true,
											});
										}}
										danger>{locale.Body.Actions.cancel}</Button>
								</ProtectedComponent>
							)
							: extra()
					}
					{
						cardData?.notes?.length
							? (
								<Button
									icon={<FileSearchOutlined />}
									onClick={() => {
										Modal.warning({
											title: locale.Modal.RejectedReasons.title,
											content: cardData.notes,
											okText: locale.Modal.RejectedReasons.close,
											centered: true,
											maskClosable: true,
										});
									}}
								>{locale.Body.Actions.seeRejectedReasons}</Button>
							)
							: null
					}
					<Button
						onClick={reviewAction}
						type='primary'
						icon={<FolderOpenOutlined />}>
						{locale.Body.Actions.review.replace('{{itemType}}', localeConfig.itemType)}
					</Button>
				</div>
				{/* End of Action Button */}

			</div>
			{/* End of Body Section */}

		</div>
	);
};

RequestCard.defaultProps = {
	cardData: {},
	cancelAction: () => null,
	reviewAction: () => null,
	extra: () => null,
	isInternal: false,
	localeConfig: {
		idType: 'Request',
		actionType: 'Schedule',
		itemType: 'Template',
	},
};

RequestCard.propTypes = {
	cardData: PropTypes.object,
	cancelAction: PropTypes.func,
	reviewAction: PropTypes.func,
	extra: PropTypes.func,
	isInternal: PropTypes.bool,
	localeConfig: PropTypes.object,
};

export default RequestCard;