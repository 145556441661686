import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useNavigate, useSearchParams} from 'react-router-dom';

import ProtectedComponent from 'components/ProtectedComponent';
import MenuPickerModal from './MenuPickerModal';
import PromoRequirement from './PromoRequirement';
import RewardSetting from './RewardSetting';

import {Button, Modal, notification} from 'antd';

import {checkSingleMenuMapping} from 'utils/request/mapping';
import {PROMO_CATEGORY, REFETCH_MAPPING_REQUEST, STORAGE_KEY, USER_ROLES} from 'utils/constants';
import {createNewPromo} from 'utils/request/promo';
import {handleErrorFetch} from 'utils/utils';

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.Internal;

const menuPickerInitialState = {
	visible: false,
	type: null,
	actionFn: () => null,
};

const InternalPromoForm = ({showRejectModal, handleApprove, loadingApprove, promoData}) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const viewOnly = JSON.parse(searchParams?.get?.('viewOnly') || null);

	const formFilledRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [menuPickerConfig, setMenuPickerConfig] = useState(menuPickerInitialState);
	const [rules, setRules] = useState({
		aov: {
			minAmount: null,
		},
		sku: {
			details: [],
		},
	});
	const [freeItemIsMapped, setFreeItemIsMapped] = useState(null);

	const [formFilled, setFormFilled] = useState({
		promoRequirement: false,
		promoRequirementPayload: {},
		rewardSetting: false,
		rewardSettingPayload: {},
	});

	const checkFreeItemMapping = async () => {
		try {
			const payload = {
				obj: {
					menuId: formFilledRef.current?.rewardSettingPayload?.rewards?.menuId,
					menuPrice: 0,
					optionGroups: [],
				},
			};

			const response = await checkSingleMenuMapping(payload);

			if (response.success) setFreeItemIsMapped(response.data.exist);
			else throw {};

		} catch (error) {
			handleErrorFetch(error);
		}
	};

	const handleMapMenu = () => {
		const freeItemEntry = formFilled.rewardSettingPayload.rewards;
		sessionStorage.setItem(STORAGE_KEY.MASTERLIST_ITEM_DETAIL, JSON.stringify(
			{
				menuDetails: {
					...freeItemEntry,
					menuPrice: 0,
					optionGroups: [],
				},
				mappingDetails: {},
			},
		));
		window.open('/masterlist/menu-structure/1/new','_blank'); // TODO Mapping
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const payload = {
				...formFilled.promoRequirementPayload,
				...formFilled.rewardSettingPayload,
				rules: {},
			};
			if (Number.isInteger(rules?.aov.minAmount)) payload.rules.aov = {minAmount: rules?.aov.minAmount};
			if (rules?.sku?.details.length !== 0) payload.rules.sku = {details: rules?.sku?.details};

			const response = await createNewPromo(payload, PROMO_CATEGORY.INTERNAL);

			if (response.success) {
				navigate('/promo/promo-request/free-item');
				notification.open({
					message: locale.Notification.Success.message,
					description: locale.Notification.Success.description,
					type: 'success',
				});
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
		}
	};

	const openMenuPicker = ({type, actionFn}) => {
		setMenuPickerConfig({
			visible: true,
			type,
			actionFn,
		});
	};

	const closeMenuPicker = () => {
		setMenuPickerConfig(menuPickerInitialState);
	};

	const listenMappingSubmit = event => {
		if (event.data === REFETCH_MAPPING_REQUEST) checkFreeItemMapping();
	};

	useEffect(() => {
		formFilledRef.current = formFilled;
	}, [formFilled]);

	useEffect(() => {
		window.addEventListener('message', listenMappingSubmit, false);

		return () => {
			window.removeEventListener('message', listenMappingSubmit);
		};
	}, []);

	useEffect(() => {
		setFreeItemIsMapped(null);
	}, [formFilled?.rewardSettingPayload?.rewards?.menuId]);

	return (
		<div className='flex flex-col gap-6'>
			<MenuPickerModal
				visible={menuPickerConfig.visible}
				actionFn={menuPickerConfig.actionFn}
				type={menuPickerConfig.type}
				onClose={closeMenuPicker}
			/>
			<PromoRequirement
				promoData={promoData}
				rules={rules}
				setRules={setRules}
				openMenuPicker={openMenuPicker}
				setFormFilled={setFormFilled}
			/>
			<RewardSetting
				promoData={promoData}
				rules={rules}
				openMenuPicker={openMenuPicker}
				setFormFilled={setFormFilled}
				freeItemIsMapped={freeItemIsMapped}
				handleMapMenu={handleMapMenu}
			/>
			<div className='flex justify-end gap-2'>
				{
					viewOnly
						? promoData?.status === 'pending'
							? (
								<>
									<ProtectedComponent
										when={u => u.roles.some(
											role => [USER_ROLES.SYNC_PROMO.ADMIN, USER_ROLES.SYNC_PROMO.APPROVER].includes(role),
										)}
									>
										{/* Review Action */}
										<Button
											loading={loadingApprove}
											danger
											onClick={() => showRejectModal()}
										>{locale.Action.reject}</Button>
										<Button
											loading={loadingApprove}
											onClick={() =>
												Modal.confirm({
													title: locale.Action.ApproveConfirmation.title,
													content: locale.Action.ApproveConfirmation.content,
													okText: locale.Action.ApproveConfirmation.approve,
													onOk: () => handleApprove(),
													centered: true,
													maskClosable: true,
												})
											}
											type='primary'>{locale.Action.approve}
										</Button>
									</ProtectedComponent>
								</>
							)
							: null
						: (
							<>
								{/* Request Action */}
								<Button>{locale.Action.cancel}</Button>
								<Button
									ghost
									type='primary'
									onClick={() => checkFreeItemMapping()}
								>{locale.Action.checkMapping}</Button>
								<Button
									loading={loading}
									disabled={
										!formFilled?.promoRequirement ||
										!formFilled?.rewardSetting ||
										(!Number.isInteger(rules?.aov.minAmount) && rules?.sku?.details.length === 0) ||
										!freeItemIsMapped
									}
									onClick={() =>
										Modal.confirm({
											title: locale.Action.CreateConfirmation.title,
											content: locale.Action.CreateConfirmation.content,
											okText: locale.Action.CreateConfirmation.approve,
											onOk: () => handleSubmit(),
											centered: true,
											maskClosable: true,
										})
									}
									type='primary'>{locale.Action.submit}</Button>
							</>
						)
				}
			</div>
		</div>
	);
};

InternalPromoForm.defaultProps = {
	showRejectModal: () => null,
	handleApprove: () => null,
	loadingApprove: false,
	promoData: {},
};

InternalPromoForm.propTypes = {
	showRejectModal: PropTypes.func,
	handleApprove: PropTypes.func,
	loadingApprove: PropTypes.bool,
	promoData: PropTypes.object,
};

export default InternalPromoForm;