import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {handleErrorFetch, promoRequestPayloadConverter} from 'utils/utils';
import {getPromoRequest} from 'utils/request/promo';
import {PROMO_TYPE} from 'utils/constants';

import debounce from 'lodash/debounce';

import {Modal, Input, Table, Popconfirm} from 'antd';
const {Search} = Input;

import localization from 'localization';
const locale = localization.Promo.CreateNewPromo.Form.PromoTemplateModal;

const LIMIT = 4;

const PromoTemplateModal = ({visible, close, populateFormField, promoType}) => {
	const defaultQueryParams = {
		label: null,
		offset: 0,
		templateType: promoType,
	};

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {label, offset} = queryParams;

	const [tableData, setTableData] = useState([]);
	const [loading, setLoading] = useState(false);

	const resetRefFlag = useRef(false);

	const handleSelectMenuTemplate = async record => {
		const promoTemplatePayload = promoRequestPayloadConverter(record);

		populateFormField(promoTemplatePayload);
		close();
	};

	const columns = [
		{
			title: 'Promo ID',
			dataIndex: 'id',
			key: 'id',
			ellipsis: true,
		},
		{
			title: 'Promo Name',
			dataIndex: 'label',
			key: 'label',
			ellipsis: true,
		},
		{
			title: 'Action',
			align: 'center',
			key: 'action',
			render: (_, record) => (
				<Popconfirm
					title={locale.Confirmation.description}
					placement="topRight"
					onConfirm={() => handleSelectMenuTemplate(record)}
				>
					<a className="text-center text-antd-blue-6">{locale.action}</a>
				</Popconfirm>
			),
		},
	];

	const fetchData = async (config = {
		reset: false,
	}) => {
		try {
			const {reset} = config;
			setLoading(true);

			let response = null;

			if (reset) {
				resetRefFlag.current = true;
				setQueryParams(defaultQueryParams);
				response = await getPromoRequest(defaultQueryParams);
			} else {
				response = await getPromoRequest(queryParams);
			}

			if (response.success) {
				setTableData(response.data);
			} else throw {};

		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setLoading(false);
			resetRefFlag.current = false;
		}
	};

	const searchTemplateList = async value => {
		const tempQueryParams = {...queryParams, offset: 0};

		value && value.length
			? tempQueryParams.label = value
			: tempQueryParams.label = null;

		setQueryParams(tempQueryParams);
	};

	const debouncedChangeHandler = useCallback(
		debounce(searchTemplateList, 500)
		, []);

	const handleChange = pagers => {
		setQueryParams({...queryParams, offset: (pagers.current - 1) * LIMIT});
	};

	useEffect(() => {
		if (!visible) return;
		fetchData({reset: true});
	}, [visible]);

	useEffect(() => {
		if (!visible) return;
		// To handle query changes
		if (resetRefFlag.current) return;
		(async () => await fetchData())();
	}, [label, offset]);

	return (
		<Modal
			destroyOnClose
			visible={visible}
			title={<div className='capitalize'>{locale.title.replace('{{type}}', promoType)}</div>}
			footer={null}
			onCancel={close}
			width={870}
			centered
		>
			<div className='flex flex-col gap-4'>
				<Search
					loading={loading}
					onChange={e => debouncedChangeHandler(e.target.value)}
					placeholder={locale.searchPlaceholder}
					className='w-1/3'
				/>

				<Table
					loading={loading}
					dataSource={tableData?.rows}
					columns={columns}
					pagination={{
						defaultPageSize: LIMIT,
						total: tableData?.count,
						current: (queryParams.offset / LIMIT) + 1,
					}}
					onChange={handleChange}
				/>

			</div>
		</Modal>
	);
};

PromoTemplateModal.defaultProps = {
	visible: false,
	close: () => null,
	populateFormField: () => null,
	promoType: null,
};

PromoTemplateModal.propTypes = {
	visible: PropTypes.bool,
	close: PropTypes.func,
	populateFormField: PropTypes.func,
	promoType: PropTypes.oneOf([PROMO_TYPE.SINGLE, PROMO_TYPE.MULTIPLE]),
};

export default PromoTemplateModal;