import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

import LinkedTemplateModal from './LinkedTemplateModal';
import MenuDrawer from 'pages/SalesChannel/MenuTemplateForm/MenuTemplateFormBody/ManageCategory/MenuDrawer';
import ProtectedComponent from 'components/ProtectedComponent';

import cloneDeep from 'lodash/cloneDeep';

import {dateFormat, handleErrorFetch} from 'utils/utils';
import {getMappings} from 'utils/request/mapping';
import {USER_ROLES} from 'utils/constants';

import './MenuStructureTable.css';

import {Badge, Button, Divider, Empty, Input, message, Select, Tag} from 'antd';
import ProTable from '@ant-design/pro-table';
import {SearchOutlined} from '@ant-design/icons';

const MENU_STRUCTURE_STATUS = {
	ACTIVE: 'active',
	INACTIVE: 'inactive',
};

const MenuStructureTable = ({posId}) => {
	const defaultQueryParams = {
		search: {},
		page: 0,
		sortBy: 'menuLabel',
		sortOrder: 'desc',
		limit: 25,
	};

	const [queryParams, setQueryParams] = useState(defaultQueryParams);
	const {search, page, sortOrder, limit} = queryParams;

	const [tableData, setTableData] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const [filteredStatus, setFilteredStatus] = useState(null);
	const [filteredTemplateId, setFilteredTemplateId] = useState(null);

	const searchInput = useRef(null);
	const tableRef = useRef(null);
	const initialLoadDoneRef = useRef(null);

	const [linkedToModalConfig, setLinkedToModalConfig] = useState({
		visible: false,
		menuStructureData: {},
	});
	const [menuDrawerConfig, setMenuDrawerConfig] = useState({
		visible: false,
		menuDataSource: {},
		hash: '',
	});

	const fetchTableData = async (config = {
		reset: false,
	}) => {
		try {
			if (!initialLoadDoneRef.current) return;
			const {reset} = config;
			setIsLoading(true);
			let response;
			if (reset) {
				setQueryParams({...defaultQueryParams, limit, posId});
				response = await getMappings({...defaultQueryParams, limit, posId});
			} else {
				response = await getMappings({...queryParams, posId});
			}
			if (response.success) {
				setTableData(response.data);
			}
		} catch (error) {
			handleErrorFetch(error);
		} finally {
			setIsLoading(false);
			initialLoadDoneRef.current = true;
		}
	};

	const handleStatus = status => {
		let statusVal = null;

		switch (status) {
		case MENU_STRUCTURE_STATUS.ACTIVE:
			statusVal = 'green';
			break;
		case MENU_STRUCTURE_STATUS.INACTIVE:
			statusVal = 'red';
			break;
		default:
			statusVal = 'green';
			break;
		}

		return (
			<Badge
				className='capitalize'
				status={statusVal}
				text={status} />
		);
	};

	const openLinkedToModal = record => {
		setLinkedToModalConfig({
			visible: true,
			menuStructureData: record,
		});
	};

	const closeLinkedToModal = () => {
		setLinkedToModalConfig({
			visible: false,
			menuStructureData: {},
		});
	};

	const openMenuDrawer = (data, hash) => {
		setMenuDrawerConfig({
			visible: true,
			menuDataSource: data,
			hash,
		});
	};

	const closeMenuDrawer = () => {
		setMenuDrawerConfig({
			visible: false,
			menuDataSource: {},
			hash: '',
		});
	};

	const handleReset = (clearFilters, confirm) => {
		clearFilters();
		confirm();
	};

	const handleCopyHash = hash => {
		navigator.clipboard.writeText(hash);
		message.success('ID Copied to Clipboard');
	};

	const getColumnSearchProps = dataIndex => ({
		// eslint-disable-next-line react/prop-types
		filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
			<div
				style={{
					padding: 8,
				}}
			>
				<Input
					ref={searchInput}
					placeholder={'Input menu template ID'}
					value={selectedKeys[0]}
					onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => confirm()}
					style={{
						marginBottom: 8,
						display: 'block',
					}}
				/>
				<div className='flex gap-1'>
					<Button
						className='flex-1'
						type="primary"
						onClick={() => confirm()}
						icon={<SearchOutlined />}
						size="small"
					>
						Search
					</Button>
					<Button
						className='flex-1'
						onClick={() => clearFilters && handleReset(clearFilters, confirm)}
						size="small"
					>
						Reset
					</Button>
				</div>
			</div>
		),
		filterIcon: filtered => (
			<SearchOutlined
				style={{
					color: filtered ? '#1890ff' : undefined,
				}}
			/>
		),
		filteredValue: filteredTemplateId || null,
		onFilter: (value, record) => record[dataIndex].some(data => data.id == value),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
			}
		},
	});

	const getColumns = () => {
		return [
			{
				title: 'Menu ID',
				dataIndex: ['MasterMenu', 'id'],
				key: 'menuId',
				fixed: 'left',
				ellipsis: true,
				width: 80,
			},
			{
				title: 'Menu Name',
				dataIndex: ['MasterMenu', 'menuLabel'],
				key: 'menuLabel',
				ellipsis: true,
			},
			{
				title: 'Linked To',
				dataIndex: 'linkedMenuTemplates',
				key: 'linkedMenuTemplates',
				width: 100,
				search: false,
				...getColumnSearchProps('linkedMenuTemplates'),
				render: (linkedMenuTemplates, record) => (
					<div
						onClick={() => openLinkedToModal(record)}
						className='text-antd-blue-6 cursor-pointer w-max'>{linkedMenuTemplates.length} Template</div>
				),
			},
			{
				title: 'Template Status',
				dataIndex: 'status',
				key: 'status',
				render: status => handleStatus(status),
				width: 100,
				search: false,
				filters: [
					{
						text: 'Active',
						value: MENU_STRUCTURE_STATUS.ACTIVE,
					},
					{
						text: 'Inactive',
						value: MENU_STRUCTURE_STATUS.INACTIVE,
					},
				],
				filterMultiple: false,
				filteredValue: filteredStatus,
				onFilter: (value, record) => record.status === value,
			},
			{
				title: 'Tags',
				dataIndex: ['MasterMenu', 'tags'],
				key: 'tags',
				width: 80,
				renderFormItem: () => (
					<Select
						open={false}
						mode='tags'
						tokenSeparators={[',']}
					/>
				),
				render: (_, record) => (Array.isArray(record?.MasterMenu?.tags) && record?.MasterMenu?.tags.length) ? (
					<div className='flex flex-wrap gap-y-2'>
						{record?.MasterMenu?.tags.map((tag, index) => (
							<Tag
								color='blue'
								className='uppercase'
								key={index}>{tag}
							</Tag>
						))}
					</div>
				) : '-',
			},
			{
				title: 'Last Mapped By',
				dataIndex: ['updater', 'username'],
				key: 'updatedBy',
				width: 100,
			},
			{
				title: 'Last Edited At',
				dataIndex: 'updatedAt',
				key: 'updatedAt',
				search: false,
				width: 150,
				render: updatedAt => dateFormat(updatedAt),
			},
			{
				title: 'Action',
				key: 'action',
				search: false,
				width: 160,
				align: 'center',
				fixed: 'right',
				render: (_, record) => (
					<div className="text-center text-blue-400 flex gap-2 items-center justify-center">
						<span
							onClick={() => openMenuDrawer(record.menuDetails, record.hash)}
							className='cursor-pointer'
						>
							Detail
						</span>
						<ProtectedComponent
							when={u => u.roles.some(role => [USER_ROLES.MASTERLIST.ADMIN, USER_ROLES.MASTERLIST.EDITOR].includes(role))}
						>
							<Divider type='vertical' />
							<span
								onClick={() => handleEditMapping(record)}
								className='cursor-pointer'
							>
								Edit Mapping
							</span>
							<Divider type='vertical' />
							<span
								onClick={() => handleCopyHash(record.hash)}
								className='cursor-pointer'
							>
								Copy ID
							</span>
						</ProtectedComponent>
					</div>
				),
			},
		];
	};

	const handleEditMapping = menuData => {
		window.open(`/masterlist/menu-structure/${posId}/${menuData.hash}/edit`, '_blank');
	};

	const handleChange = (pagers, filters) => {
		setFilteredTemplateId(filters.linkedMenuTemplates || null);
		setFilteredStatus(filters.status || null);
		const variables = {
			limit: pagers.pageSize,
			page: pagers.current - 1,
		};
		setQueryParams({...queryParams, ...variables});
	};

	useEffect(() => {
		initialLoadDoneRef.current = false;
		setTableData([]);
	}, [posId]);

	useEffect(() => {
		fetchTableData();
	}, [search, page, sortOrder, limit, posId]);

	return (
		<div
			ref={tableRef}
			className='px-6'>
			<LinkedTemplateModal
				visible={linkedToModalConfig.visible}
				menuStructureData={linkedToModalConfig.menuStructureData}
				close={closeLinkedToModal}
			/>
			<MenuDrawer
				visible={menuDrawerConfig.visible}
				menuDataSource={menuDrawerConfig.menuDataSource}
				handleCopyHash={() => handleCopyHash(menuDrawerConfig.hash)}
				onClose={closeMenuDrawer}
				viewOnly
				isMenuStructure
				editMapping={() => handleEditMapping(menuDrawerConfig)}
			/>
			<ProTable
				rowKey='id'
				locale={{emptyText: <Empty description='To view data on this page, please initiate a search using the filtering feature' />}}
				id='menu-structure-table'
				loading={isLoading}
				className='MenuStructureTable'
				onReset={() => {
					setFilteredStatus(null);
					setFilteredTemplateId(null);
					fetchTableData({reset: true});
				}}
				getPopupContainer={() =>
					document.getElementById('menu-structure-table')
				}
				options={false}
				search={{
					layout: 'vertical',
				}}
				pagination={{
					defaultPageSize: limit,
					current: page + 1,
					total: tableData?.count,
					showSizeChanger: true,
					pageSizeOptions: ['10', '25', '50', '100'],
					showQuickJumper: true,
				}}
				onChange={handleChange}
				onSubmit={params => {
					Object.keys(params).forEach(k => !params[k] && delete params[k]);

					if (Array.isArray(params.tags) && params.tags.length) {
						params.tags = params.tags.join(',').toLowerCase();
					} else {
						delete params.tags;
					}

					const queryParamsTemp = cloneDeep({...defaultQueryParams, posId});
					queryParamsTemp.search = {...params};
					queryParamsTemp.limit = queryParams.limit;

					setQueryParams(queryParamsTemp);
				}}
				scroll={{x: 'max-content'}}
				dataSource={tableData?.rows}
				columns={getColumns()}
			/>
		</div>
	);
};

MenuStructureTable.defaultProps = {
	posId: null,
};

MenuStructureTable.propTypes = {
	posId: PropTypes.number,
};

export default MenuStructureTable;