import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';

// Local Component
import OrderMenuDrawer from './OrderMenuDrawer';
import MenuTags from './MenuTags';
import CategoryItem from './CategoryItem';
import MenuItem from './MenuItem';
import MenuDrawer from './MenuDrawer';
import SelectMenuModal from './SelectMenuModal';
import OutletSettingDrawer from './OutletSettingDrawer';
import Context from '../Context';
import MenuOutletList from './MenuOutletList';

// Package
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import {Alert, Button, Empty} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';

import {looseSearch} from 'utils/utils';

import localization from 'localization';
const locale = localization.MenuTemplate.MenuTemplateForm.ManageCategory;

const INITIAL_ORDER_DRAWER_DATA = {
	visible: false,
	menuData: {},
	categoryData: {},
};

const INITIAL_MENU_OUTLET_LIST_DRAWER_STATE = {
	open: false,
	title: null,
	locationIds: [],
};

const ManageCategory = ({
	menuDraft, setMenuDraft, goToChooseCategory, handleSubmitToOrderhub, viewOnly, promoView,
	closeConfirmation, handleCheckMappings, unmapMenu, duplicateChildMenuFounds, duplicateParentMenuFounds,
	setCart,
}) => {
	const {previewAs, isMenuCreator, searchString} = useContext(Context);
	const [selectedCategoryId, setSelectedCategoryId] = useState(menuDraft?.[0]?.categoryId);

	const [menuDrawerData, setMenuDrawerData] = useState({});
	const [menuDrawerVisible, setMenuDrawerVisible] = useState(false);

	const [orderDrawerConfig, setOrderDrawerConfig] = useState(INITIAL_ORDER_DRAWER_DATA);

	const [menuTagData, setMenuTagData] = useState({});
	const [menuTagVisible, setMenuTagVisible] = useState(false);

	const [outletSettingDrawerVisible, setOutletSettingDrawerVisible] = useState(false);

	const [showSelectMenuModal, setShowSelectMenuModal] = useState(false);
	const [isEditMenu, setIsEditMenu] = useState(false);
	const [menuOriginIndex, setMenuOriginIndex] = useState(null); // used to track menu index in category

	const [menuOutletListDrawerConfig, setMenuOutletListDrawerConfig] = useState(INITIAL_MENU_OUTLET_LIST_DRAWER_STATE);

	const closeMenuOutletListDrawer = () => setMenuOutletListDrawerConfig(INITIAL_MENU_OUTLET_LIST_DRAWER_STATE);

	const openMenuOutletListDrawer = (title, locationIds) => {
		setMenuOutletListDrawerConfig({
			open: true,
			title,
			locationIds,
		});
	};

	const handleAddToCart = cartItem => {
		setCart(cartItem);
	};

	const getCategoryIndexById = categoryId => {
		return menuDraft.findIndex(category => category.categoryId === categoryId);
	};

	const openOrderMenuDrawer = (menuData, categoryData) => {
		setOrderDrawerConfig({
			visible: true,
			menuData,
			categoryData,
		});
	};

	// Drag n Drop Handler
	const handleOpenMenuDrawer = (menu, index) => {
		setMenuDrawerData(menu);
		setMenuOriginIndex(index);
		if (!viewOnly) setIsEditMenu(true);
		setMenuDrawerVisible(true);
	};

	const handleOnDragEndCategory = result => {
		if (!result.destination) return;

		const items = cloneDeep(menuDraft);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);

		setMenuDraft(items);
	};

	const handleOnDragEndMenu = result => {
		if (!result.destination) return;

		const categoriesTemp = cloneDeep(menuDraft);
		const categoryIndex = categoriesTemp.findIndex(category => category.categoryId === selectedCategoryId);
		const items = cloneDeep(categoriesTemp[categoryIndex]);
		const [reorderedItem] = items.menus.splice(result.source.index, 1);
		items.menus.splice(result.destination.index, 0, reorderedItem);

		categoriesTemp[categoryIndex] = items;

		setMenuDraft(categoriesTemp);
	};

	const getCategoryStats = () => {
		let activeCategory = menuDraft[0];
		if (selectedCategoryId) {
			activeCategory = menuDraft.find(category => category.categoryId === selectedCategoryId);
		}

		return {
			activeCategory,
			categories: menuDraft,
		};
	};

	const handleManageMenu = selectedMenu => {
		// To persist current option group when changing menu
		if (menuDrawerData) selectedMenu.optionGroups = menuDrawerData.optionGroups;
		delete selectedMenu.kdsName;
		setMenuDrawerData(selectedMenu);
		setMenuDrawerVisible(true);
	};

	const pickMenu = () => {
		setMenuDrawerVisible(false);
		setShowSelectMenuModal(true);
	};

	const handleAddMenuToCategory = payload => {
		const categoryToPushIndex = menuDraft.findIndex(category => category.categoryId === payload.categoryId);
		delete payload.categoryId;

		const activeCategoryIndex = menuDraft.findIndex(category => category.categoryId === selectedCategoryId);
		const differentCategory = categoryToPushIndex !== activeCategoryIndex;

		if (categoryToPushIndex >= 0) {
			const tempCategories = cloneDeep(menuDraft);

			if (isEditMenu) {
				if (differentCategory) {
					// If edit and the category is changed remove menu from origin category then insert menu to new category
					tempCategories[activeCategoryIndex].menus = tempCategories[activeCategoryIndex].menus.filter((_, index) => index !== menuOriginIndex);
					tempCategories[categoryToPushIndex].menus.splice(menuOriginIndex, 0, payload);
				} else {
					// If edit and the category is same find index of the menu then replace the menu with new data
					tempCategories[categoryToPushIndex].menus[menuOriginIndex] = payload;
				}
			} else {
				// If new entry (not edit) push menu immediately to selected category
				tempCategories[categoryToPushIndex].menus.push(payload);
			}

			setMenuDraft(tempCategories);
			setMenuDrawerVisible(false);
			setIsEditMenu(false);
			setMenuOriginIndex(null);
			setMenuDrawerData({});
		}
	};

	const handleRemoveMenuFromCategory = menuIndex => {
		const categoryIndex = getCategoryIndexById(selectedCategoryId);
		const tempCategories = cloneDeep(menuDraft);
		tempCategories[categoryIndex].menus = tempCategories[categoryIndex].menus.filter((_, index) => index !== menuIndex);
		setMenuDraft(tempCategories);
	};

	const alertHandler = ({
		type, // one of `category` or `menu`
		category, // used for `category`
		menu, // used for `menu`
	}) => {
		let output = false;
		if (duplicateChildMenuFounds.length || duplicateParentMenuFounds.length) {
			// For duplicate alert
			if (type === 'category') {
				// Checker for duplicate child menu in category level
				if (duplicateChildMenuFounds.some(found => found.categoryId === category.categoryId)) output = true;
				// Checker for duplicate parent menu in category level
				if (category.menus.some(menu => duplicateParentMenuFounds.includes(menu.menuId))) output = true;
			} else if (type === 'menu') {
				// Checker for duplicate child menu in menu level
				if (duplicateChildMenuFounds.some(found => found.menuId === menu.menuId)) output = true;
				// Checker for duplicate parent menu in category level
				if (duplicateParentMenuFounds.includes(menu.menuId)) output = true;
			}

		} else {
			// For unmap alert
			if (type === 'category') {
				if ((category.menus.filter(o1 => unmapMenu.some(o2 => o1.menuId == o2.menuId))).length) output = true;
			} else if (type === 'menu') {
				if (unmapMenu.some(unmapMenu => unmapMenu.menuId == menu.menuId)) output = true;
			}

		}

		return output;
	};

	const unmapMenuPosIds = menu => {
		// Filter unmapMenu by current menu and return unmap posId
		return unmapMenu.filter(unmapMenu => unmapMenu.menuId == menu.menuId).map(unmapMenu => unmapMenu.posId);
	};

	const filteredCategory = () => {
		if (!previewAs) return menuDraft;
		else {
			const output = menuDraft.filter(
				category => category.locationFilter.type === 'default' ||
				(category.locationFilter.type === 'include' && category.locationFilter.locationIds.some(id => id == previewAs)) ||
				(category.locationFilter.type === 'exclude' && !category.locationFilter.locationIds.some(id => id == previewAs)),
			);

			// Check if current selected category exist in filtered category
			const currentCategoryStillVisible = output.some(category => category.categoryId === selectedCategoryId);

			// If yes do nothing
			// If no set selected category to first index of filtered category
			if (!currentCategoryStillVisible) setSelectedCategoryId(output[0].categoryId);
			return output;
		}
	};

	const filteredMenu = () => {
		const flattenMenu = menuDraft.map(obj => obj.menus).flat();

		const filteredMenu = searchString
			? flattenMenu?.filter(menu => looseSearch(menu.menuLabel, searchString))
			: flattenMenu;

		const menuEntryPoint = searchString?.length ? filteredMenu : getCategoryStats().activeCategory?.menus;
		if (!previewAs) return menuEntryPoint;
		else {
			const output = menuEntryPoint.filter(
				menu => menu.locationFilter.type === 'default' ||
				(menu.locationFilter.type === 'include' && menu.locationFilter.locationIds.some(id => id == previewAs)) ||
				(menu.locationFilter.type === 'exclude' && !menu.locationFilter.locationIds.some(id => id == previewAs)),
			);

			return output;
		}
	};

	const getLocationFilterSetting = index => {
		const currentCategory = getCategoryStats().activeCategory;
		if (currentCategory.locationFilter?.type !== 'default') {
			return currentCategory.locationFilter;
		}

		const currentMenus = filteredMenu();
		return currentMenus[index].locationFilter;
	};

	const openMenuTag = (menuData, index) => {
		setMenuOriginIndex(index);
		setMenuTagData(menuData);
		setMenuTagVisible(true);
	};

	const closeMenuTag = () => {
		setMenuTagData({});
		setMenuTagVisible(false);
		setMenuOriginIndex(null);
	};

	const toggleMenuHidden = menuIndex => {
		const categoryIndex = getCategoryIndexById(selectedCategoryId);
		const tempCategories = cloneDeep(menuDraft);
		const menuIsHidden = get(tempCategories[categoryIndex].menus[menuIndex], 'hideInPlatform', false);
		tempCategories[categoryIndex].menus[menuIndex].hideInPlatform = !menuIsHidden;
		setMenuDraft(tempCategories);
	};

	const saveMenuTag = selectedTag => {
		const tempCategories = cloneDeep(menuDraft);

		const activeCategoryIndex = menuDraft.findIndex(category => category.categoryId === selectedCategoryId);
		tempCategories[activeCategoryIndex].menus[menuOriginIndex] = {
			...menuTagData,
			menuTags: selectedTag,
		};

		setMenuDraft(tempCategories);
		closeMenuTag();
	};

	return (
		<>
			<MenuTags
				visible={menuTagVisible}
				menuTagData={menuTagData}
				onSave={saveMenuTag}
				close={() => closeMenuTag()}
			/>
			<OrderMenuDrawer
				visible={orderDrawerConfig.visible}
				menuData={orderDrawerConfig.menuData}
				categoryData={orderDrawerConfig.categoryData}
				onClose={() => setOrderDrawerConfig(INITIAL_ORDER_DRAWER_DATA)}
				onSave={handleAddToCart}
			/>
			<MenuOutletList
				onClose={closeMenuOutletListDrawer}
				open={menuOutletListDrawerConfig.open}
				title={menuOutletListDrawerConfig.title}
				locationIds={menuOutletListDrawerConfig.locationIds}
			/>
			<MenuDrawer
				pickMenu={pickMenu}
				getCategoryStats={getCategoryStats}
				menuDataSource={menuDrawerData}
				visible={menuDrawerVisible}
				onClose={(config = {
					clearData: false,
				}) => {
					if (config.clearData) {
						setMenuDrawerData({});
					}
					setMenuDrawerVisible(false);
				}}
				addMenuToCategory={handleAddMenuToCategory}
				isEdit={isEditMenu}
				setIsEdit={setIsEditMenu}
				viewOnly={viewOnly}
				setMenuDraft={setMenuDraft}
			/>
			<OutletSettingDrawer
				categoryData={getCategoryStats()}
				visible={outletSettingDrawerVisible}
				close={() => setOutletSettingDrawerVisible(false)}
				setMenuDraft={setMenuDraft}
			/>
			<SelectMenuModal
				isEdit={isEditMenu}
				visible={showSelectMenuModal}
				onClose={() => {
					setShowSelectMenuModal(false);
					if (Object.keys(menuDrawerData).length) {
						// if menu drawer data still exist then open menu drawer (usually happen in edit scenario)
						setMenuDrawerVisible(true);
					}
				}}
				handleManageMenu={handleManageMenu}
			/>
			{
				duplicateChildMenuFounds.length || duplicateParentMenuFounds.length || unmapMenu.length
					? (
						<Alert
							className='mb-4'
							message={
								duplicateChildMenuFounds.length || duplicateParentMenuFounds.length
									? locale.duplicateMenuIdAlert
									: locale.unmapMenuAlert.replace('{{unmapMenuQty}}', unmapMenu.length)
							}
							type="error"
							icon={<ExclamationCircleFilled />}
							showIcon />
					)
					: null
			}
			<div
				style={{
					minHeight: 600,
					height: '90vh',
				}}
				className='pb-8 flex flex-col'>
				<div className='overflow-hidden flex-1 flex border border-antd-netural-4 rounded-sm'>
					{
						searchString ? null : (
							<div className='flex flex-col max-w-sm'>
								<div className='flex bg-antd-netural-12 text-white p-4 justify-between box-border'>
									<div className='text-sm leading-5 font-medium'>{locale.categoryLabel}</div>
									{
										viewOnly
											? null
											: previewAs
												? null
												: (
													<div
														onClick={goToChooseCategory}
														className='text-xs leading-5 font-normal bg-antd-blue-6 px-2 rounded-sm cursor-pointer'>{locale.manageCategory}</div>
												)
									}
								</div>
								<DragDropContext onDragEnd={handleOnDragEndCategory}>
									<Droppable droppableId='categories'>
										{provided => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
												className='overflow-auto h-full border-r border-antd-netural-4'
											>
												{
													filteredCategory().map((item, index) => (
														<Draggable
															key={`${item.categoryLabel}-${item.categoryId}`}
															draggableId={`${item.categoryLabel}-${item.categoryId}`}
															isDragDisabled={viewOnly}
															index={index}>
															{provided => (
																<CategoryItem
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	dragHandleProps={provided.dragHandleProps}
																	key={index}
																	selectedCategoryId={selectedCategoryId}
																	category={item}
																	onClickItem={() => setSelectedCategoryId(item.categoryId)}
																	index={index}
																	viewOnly={viewOnly}
																	alertVisible={alertHandler({type: 'category', category: item})}
																/>
															)}
														</Draggable>
													))
												}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
							</div>
						)
					}
					<div className='flex-1 flex flex-col'>
						<div className='flex justify-between bg-antd-netural-12 text-white p-4'>
							<div className='text-sm leading-5 font-medium'>{locale.menuLabel}</div>
							<div className='flex gap-2'>
								{
									(previewAs)
										? null
										: isMenuCreator ? null : (
											<div
												onClick={() => setOutletSettingDrawerVisible(true)}
												className='text-xs leading-5 font-normal bg-antd-blue-6 px-2 rounded-sm cursor-pointer'>
												{locale.outletSetting}
											</div>
										)
								}
								{
									viewOnly
										? null
										: previewAs
											? null
											: (
												<div
													onClick={pickMenu}
													className='text-xs leading-5 font-normal bg-antd-blue-6 px-2 rounded-sm cursor-pointer'>
													{locale.selectMenu}
												</div>
											)
								}
							</div>
						</div>
						{
							filteredMenu().length
								? (
									<DragDropContext onDragEnd={handleOnDragEndMenu}>
										<Droppable droppableId='menus'>
											{provided => (
												<div
													{...provided.droppableProps}
													ref={provided.innerRef}
													className='overflow-auto h-full'>
													{filteredMenu()
														.map((menu, index) => (
															<Draggable
																key={`${menu.menuLabel}-${menu.menuId}`}
																draggableId={`${menu.menuLabel}-${menu.menuId}`}
																isDragDisabled={viewOnly}
																index={index}>
																{provided => (
																	<MenuItem
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		dragHandleProps={provided.dragHandleProps}
																		openMenuDrawer={() => handleOpenMenuDrawer(menu, index)}
																		removeMenu={() => handleRemoveMenuFromCategory(index)}
																		menu={menu}
																		openMenuTag={() => openMenuTag(menu, index)}
																		locationFilterSetting={getLocationFilterSetting(index)}
																		duplicateChildMenuExist={Boolean(duplicateChildMenuFounds.length)}
																		unmapMenuPosIds={unmapMenuPosIds(menu)}
																		viewOnly={viewOnly}
																		promoView={promoView}
																		toggleMenuHidden={() => toggleMenuHidden(index)}
																		key={`${menu.menuId} ${index}`}
																		openOrderMenuDrawer={() => openOrderMenuDrawer(menu, getCategoryStats().activeCategory)}
																		openMenuOutletListDrawer={openMenuOutletListDrawer}
																	/>
																)}
															</Draggable>
														))
													}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
								)
								: (
									<div className='h-full flex items-center justify-center'>
										<Empty
											className='flex flex-col items-center justify-center pt-4'
											image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
											imageStyle={{
												height: 60,
											}}
											description={locale.menuEmptyPlaceholder.replace('{{categoryLabel}}', getCategoryStats().activeCategory?.categoryLabel)}
										>
											{
												!viewOnly && (
													<Button
														onClick={pickMenu}
														type="primary">{locale.selectMenu}
													</Button>
												)
											}
										</Empty>
									</div>
								)
						}
					</div>
				</div>

				{!viewOnly && (
					<div className='flex justify-end gap-2 pt-6 pb-10'>
						<Button
							onClick={closeConfirmation}
						>{locale.cancel}</Button>
						<Button
							ghost
							disabled={duplicateChildMenuFounds.length || duplicateParentMenuFounds.length || previewAs}
							onClick={handleCheckMappings}
							type='primary'
						>{locale.checkMapping}</Button>
						<Button
							disabled={duplicateChildMenuFounds.length || duplicateParentMenuFounds.length || previewAs}
							onClick={handleSubmitToOrderhub}
							type='primary'
						>{locale.submit}</Button>
					</div>
				)}
			</div>
		</>
	);
};

ManageCategory.defaultProps = {
	menuDraft: [],
	goToChooseCategory: () => null,
	setMenuDraft: () => null,
	handleSubmitToOrderhub: () => null,
	handleCheckMappings: () => null,
	viewOnly: false,
	promoView: false,
	closeConfirmation: () => null,
	unmapMenu: [],
	duplicateChildMenuFounds: [],
	duplicateParentMenuFounds: [],
	setCart: () => null,
};

ManageCategory.propTypes = {
	menuDraft: PropTypes.array,
	goToChooseCategory: PropTypes.func,
	setMenuDraft: PropTypes.func,
	handleSubmitToOrderhub: PropTypes.func,
	handleCheckMappings: PropTypes.func,
	viewOnly: PropTypes.bool,
	promoView: PropTypes.bool,
	closeConfirmation: PropTypes.func,
	unmapMenu: PropTypes.array,
	duplicateChildMenuFounds: PropTypes.array,
	duplicateParentMenuFounds: PropTypes.array,
	setCart: PropTypes.func,
};

export default ManageCategory;